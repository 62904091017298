import axios from "axios";

// Set config defaults when creating the instance
const apiV1Instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

export const apiCall = ({endpoint, method, data}) => new Promise((resolve, reject) => {
    let token = localStorage.getItem('web-user-token');
    let contentType = 'application/x-www-form-urlencoded';
    if (data !== null && typeof data === 'object') {
        contentType = 'application/json;charset=UTF-8'
    }

    apiV1Instance({
        method: method,
        data: data,
        url: endpoint,
        headers: { 'Content-Type': contentType, 'Authorization':'Bearer ' + token }
    })
    .then(response => {
        resolve(response)
    })
    .catch(err => {
        reject(new Error(err))
    })
});

export const apiAuthCall = ({endpoint, method, data}) => new Promise((resolve, reject) => {
    axios({
        method: method,
        url: process.env.VUE_APP_API_URL + endpoint,
        data: data,
        headers: { 'Content-Type': 'application/json;charset=UTF-8'}
    })
    .then(response => {
        resolve(response.data.result);
    })
    .catch(err => {
        reject(new Error(err))
    })
});

export const apiDownloadFile = ({endpoint, fileName}) => new Promise((resolve, reject) => {
    let token = localStorage.getItem('web-user-token');
    apiV1Instance({
        method: 'get',
        url: endpoint,
        // headers: { 'Authorization':'Bearer ' + token},
        responseType: 'arraybuffer',
    })
    .then(response => {
        console.log('Download: ' + fileName);
        const url = window.URL.createObjectURL(new Blob([response.data], { type:"audio/mpeg" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    })
    .catch(err => {
        reject(new Error(err))
    })
});